@import 'utils/_variables';

.__smart-meters-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2 {
    max-width: 1100px;
    width: 100%; }

  h1, h2, h3, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    letter-spacing: 3px;
    margin-bottom: 20px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 28px;
      line-height: 60px;
      color: $dark-grey-2; } }

  ul {
    list-style: none; }

  p, li {
    font-family: $helvetica;
    font-size: 20px;
    line-height: 40px;
    color: $dark-grey; }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      line-height: 24px;
      padding: 12px 60.5px; } } }


.__smart-meters-header {
  background: transparent url('/img/technology/background-banner-smart-meters.jpg') 50% 50% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    align-items: center;
    height: 160px; }

  h1 {
    text-transform: capitalize;
    font-size: 45px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      width: 160px;
      margin-left: 0px; } } }

.__smart-meters-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $sm-max) {
    margin: 50px 0; }

  &#smart-meter-integration {
    > h3:first-child {
      max-width: 650px;
      + h2 {
        margin-top: 50px; } }

    h2 {
      margin-top: 160px;

      @media (max-width: $sm-max) {
        margin-top: 70px; } }

    h3 {
      font-family: $helvetica;
      width: 825px;

      @media (max-width: $sm-max) {
        font-size: 16px;
        line-height: 24px;
        width: 219px; }

      &#subtitle {
        @media (max-width: $sm-max) {
          font-size: 12px;
          line-height: 18px;
          width: 276px; } } }

    >figure {
      margin-top: 40px;
      margin-bottom: 60.06px;

      @media (max-width: $sm-max) {
        margin-top: 30.28px; } }

    .smart-meter-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .smart-meter-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } }

    p {
      max-width: 1020px;
      padding: 0 10px;
      width: 100%;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px; } }

    .__smart-meters-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 1100px;
      width: 100%;

      > figure {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 15px 0;
        text-align: center;
        @media (max-width: $sm-max) {
          flex-basis: 100%; }

        img {
          display: block;
          margin: 0 auto;
          max-width: 100%; }

        figcaption,
        p {
          font-family: $quicksand;
          font-weight: 600; }
        p {
          font-size: 26px;
          margin: 0;
          padding: 0; }
        figcaption {
          font-size: 20px; }
        .green {
          color: $dark-green; } }

      &--smaller-images {
        margin-bottom: 50px;
        max-width: 1250px;

        > figure img {
          max-height: 250px;
          max-width: 70%; } } }

    .__smart-meters-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 910px;

      @media (max-width: $sm-max) {
        flex-direction: column-reverse; }

      .__smart-meters-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        figure {
          @media (max-width: $sm-max) {
            margin-top: 30px; }

          img {
            @media (max-width: $sm-max) {
              display: block;
              max-width: 191px;
              max-height: 191px;
              width: auto;
              height: auto; } } }

        h3 {
          @media (max-width: $sm-max) {
            font-family: $quicksand;
            font-size: 20px;
            line-height: 53px; } } } }

    .__last-p {
      @media (max-width: $sm-max) {
        width: 305px; } }

    div {
      margin-top: 40px;

      @media (max-width: $sm-max) {
        margin-top: 60px;
        margin-bottom: 101px; } } } }
