@import 'utils/_variables';

.__voltaware-sensors-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2 {
    width: 1100px; }

  h1, h2, h3, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    letter-spacing: 3px;
    margin-bottom: 20px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 24px;
      line-height: 40px;
      color: $dark-grey-2; } }

  ul {
    list-style: none; }

  p, li {
    font-family: $helvetica;
    font-size: 20px;
    line-height: 40px;
    color: $dark-grey; }

  li {
    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 30px; } }

  p {
    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 18px; } }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px; } }

.__voltaware-sensors-header {
  background: transparent url('/img/technology/background-banner-voltaware-sensors.jpg') 29% 50% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    align-items: center;
    height: 160px; }

  h1 {
    font-size: 50px;
    letter-spacing: 10px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0px;
      text-transform: uppercase;
      width: 375px;
      margin-left: 0px; } } }

.__voltaware-sensors-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $sm-max) {
    margin: 50px 0; }

  >h3 {
    font-size: 28px;
    line-height: 60px;
    font-family: $helvetica;

    @media (max-width: $sm-max) {
      width: 198px; } }

  &#presenting-sensor {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $sm-max) {
      flex-direction: column; }

    figure {
      img {
        @media (max-width: $sm-max) {
          display: block;
          max-width: 174px;
          max-height: 245px;
          width: auto;
          height: auto; } } }

    .__presenting-sensor-wrap {
      @media (max-width: $sm-max) {
        margin-top: 40px; }

      h2 {
        font-size: 40px;
        line-height: 120px;
        width: 591px;
        text-align: left;
        margin-left: 74px;

        @media (max-width: $sm-max) {
          font-size: 22px;
          line-height: 50px;
          width: 286px;
          text-align: center;
          margin-left: 0px;
          margin-bottom: 0px; } } } }

  &#only-of-its-kind {
    .__only-of-its-kind-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 57px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        margin-top: 0px; }

      figure {
        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 165px;
            max-height: 324px;
            width: auto;
            height: auto; } } }

      .__article-wrap {
        height: 526px;

        @media (max-width: $sm-max) {
          height: auto; }

        p {
          line-height: 34px;
          width: 528px;
          margin-left: 56px;
          margin-top: 30px;

          @media (max-width: $sm-max) {
            line-height: 18px;
            width: 316px;
            margin: 0px; } }

        p + p {
          @media (max-width: $sm-max) {
            margin-top: 30px; } } } } }

  &#cutting-edge-technology {
    .__cutting-edge-technology-wrap {
      display: flex;
      flex-direction: row;

      @media (max-width: $sm-max) {
        flex-direction: column;
        justify-content: center; }

      figure {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $sm-max) {
          margin-top: 30px; }

        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 269px;
            max-height: 329px;
            width: auto;
            height: auto; } } }

      .__article-wrap {
        margin-left: 50px;

        @media (max-width: $sm-max) {
          margin-left: 0px; }

        .__cutting-edge-technology-article {
          h3 {
            font-size: 30px;
            line-height: 80px;
            text-align: left;

            @media (max-width: $sm-max) {
              font-size: 18px;
              line-height: 40px;
              text-align: center;
              margin-top: 30px; } }

          p {
            line-height: 34px;
            width: 506px;

            @media (max-width: $sm-max) {
              line-height: 18px;
              width: 301px; } } } } } }

  &#easy-instalattion {
    .__easy-installation-wrap {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      @media (max-width: $sm-max) {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 0px; }

      figure {
        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 304px;
            max-height: 274px;
            width: auto;
            height: auto; } } }

      .__article-wrap {
        margin-left: 42px;

        @media (max-width: $sm-max) {
          margin-left: 0px; }

        p {
          line-height: 34px;
          width: 528px;

          @media (max-width: $sm-max) {
            line-height: 18px;
            width: 314px; } }

        p + p {
          margin-top: 30px; } } } }

  &#compatibility {
    h3.dark-grey {
      @media (max-width: $sm-max) {
        font-family: $roboto;
        font-size: 14px;
        line-height: 34px;
        font-weight: 400;
        width: 232px; } }

    .__section-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 928px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        width: initial; }

      figure {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        margin-top: 80px;

        @media (max-width: $sm-max) {
          justify-content: center;
          width: 100%;
          margin-top: 0px; } }

      .__single-phase-sensor {
        img {
          @media (max-width: $sm-max) {
            position: relative;
            display: block;
            max-width: 275px;
            max-height: 164px;
            width: auto;
            height: auto;
            margin-left: 0px; } } }

      .__three-phase-sensor {
        @media (max-width: $sm-max) {
          margin-top: 46px; }

        img {
          position: absolute;
          margin-left: -60px;

          @media (max-width: $sm-max) {
            position: relative;
            display: block;
            max-width: 155px;
            max-height: 189px;
            width: auto;
            height: auto;
            margin-left: 0px; } } }

      .__article-wrap {
        margin-left: 44px;
        width: 50%;

        @media (max-width: $sm-max) {
          margin-left: 0px;
          width: 100%; }

        h3 {
          font-size: 28px;
          line-height: 80px;
          text-align: left;

          @media (max-width: $sm-max) {
            font-size: 14px;
            line-height: 80px;
            text-align: center;
            margin-bottom: 0px; } }

        ul {
          list-style-type: disc;

          li {
            margin-left: 66px;

            @media (max-width: $sm-max) {
              margin-left: 0px; } } } } } } }
