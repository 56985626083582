@import 'utils/_variables';

.__continuous-learning-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .__br {
    display: none;

    @media (max-width: $sm-max) {
      display: block; } }

  h1, h2 {
    width: 1100px; }

  h1, h2, h3, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    letter-spacing: 3px;
    margin-bottom: 20px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 26px;
      line-height: 40px;
      color: $dark-grey-2; } }

  ul {
    list-style: none; }

  p, li {
    font-family: $helvetica;
    font-size: 20px;
    line-height: 40px;
    color: $dark-grey; }

  p {
    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 18px; } }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px; } }

.__continuous-learning-header {
  background: transparent url('/img/technology/background-banner-continuous-learning.jpg') 50% 50% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    height: 160px; }

  h1 {
    text-transform: capitalize;
    font-size: 45px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin-left: 0px; } } }

.__continuous-learning-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $sm-max) {
    margin: 40px 0; }

  &#training-our-algorithm {
    h2 {
      margin-bottom: 0px; }

    .training-our-algorithm-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .training-our-algorithm-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } }

    figure {
      margin-top: 60.5px;
      margin-bottom: 41.48px;

      @media (max-width: $sm-max) {
        margin-top: 30.74px;
        margin-bottom: 30.42px; } }

    p {
      width: 1120px;

      @media (max-width: $sm-max) {
        width: 300px; } } }

  &#providing-best-results {
    @media (max-width: $sm-max) {
      margin-top: 30px; }

    p {
      width: 1120px;

      @media (max-width: $sm-max) {
        width: 300px; } }

    .providing-best-results-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .providing-best-results-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } } }

  &#machine-learning-feedback {
    h2 {
      @media (max-width: $sm-max) {
        margin-bottom: 0px; } }

    .__article-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 62px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        margin-top: 30px; }

      figure {
        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 169px;
            max-height: 341px;
            width: auto;
            height: auto; } } }

      article {
        p {
          width: 641px;
          margin-left: 53px;

          @media (max-width: $sm-max) {
            width: 305px;
            margin-left: 0px;
            margin-top: 30px; } }

        p + p {
          margin-top: 50px;

          @media (max-width: $sm-max) {
            margin-top: 30px; } } } } } }
