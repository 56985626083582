// Colors
$black: #000000;
$green: #6ab42d;
$dark-green: #4B8C2A;
$green-light: #d5ffb2;
$dark-grey: #5a5a5a;
$dark-grey-2: #666666;
$dark-grey-3: #999999;
$light-grey: #b5b5b5;
$light-grey-2: #FBFBFB;
$white: #fff;
$sensor-blue: #03c;
$sensor-red: #fe0003;
$sensor-yellow: #e5c800;
$sensor-green: #35cc01;
$yellow: #F2A100;
$dark-yellow: #db9200;
$orange: #F15A22;
$dark-orange: #d8440e;

// Sizes
$xxs: 479px;
$xs: 480px;
$xs-max: 767px;
$sm: 768px;
$sm-max: 991px;
$md: 992px;
$md-max: 1199px;
$lg: 1200px;
$container-sm-width: 800px;
$container-md-width: 1000px;
$container-lg-width: $lg;

$header-height: 100px;

// Fonts
$helvetica-name: 'HelveticaNeue-Regular';
$helvetica: $helvetica-name, sans-serif;
$helvetica-medium-name: 'HelveticaNeue-Medium';
$helvetica-medium: $helvetica-medium-name, sans-serif;
$helvetica-thin-name: 'HelveticaNeue-Thin';
$helvetica-thin: $helvetica-thin-name, sans-serif;
$quicksand: 'Quicksand', sans-serif;
$roboto: 'Roboto', sans-serif;
