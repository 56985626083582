@import 'utils/_variables';

.banner {
    height: calc(100vh - 100px);
    min-height: 600px;
    background-color: #555;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @media (max-width: 959px) {
        height: calc(100vh - 70px); }

    &.home {
        &:before {
            content: '';
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: url(/img/home/banner-donut-graphic.png) no-repeat top right;
            background-size: auto 100%;

            @media (max-width: 959px) {
                display: none; } }

        @media (max-width: 959px) and (min-width: 360px) {
            &:after {
                background: url(/img/home/banner-background-mobile.png) no-repeat center bottom;
                background-size: contain;
                content: '';
                width: 100%;
                height: 40vh;
                position: absolute;
                left: 0;
                bottom: 0; } } }

    &.support {
        margin: 40px auto 0;
        max-width: calc(100% - 60px);
        border-radius: 22px;
        height: unset;
        min-height: 400px;

        @media (max-width: 959px) {
            margin: 0;
            border-radius: 0;
            max-width: unset;
            width: 100%; }

        p {
            font-size: 24px; }

        .banner-wrap {
            padding-top: 4vw;

            @media (max-width: 959px) {
                padding-top: 30px; }

            .text {
                max-width: unset;

                p {
                    @media (max-width: 959px) {
                        font-size: 20px;
                        margin-top: 16px; } } } } } }

.banner-wrap {
    padding: 8vw 0 0;
    margin: 0 auto;
    max-width: 850px;

    @media (max-width: 959px) {
        padding: 2rem; }

    .text {
        max-width: 550px; }

    h1, h2.h1 {
        letter-spacing: 2px;
        font-family: $quicksand;
        font-size: 64px;
        line-height: 120%;
        font-weight: 700;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0,0,0,.5);

        @media (max-width: 959px) {
            font-size: 40px; } }

    p {
        font-family: $quicksand;
        font-size: 30px;
        color: #eee;
        margin: 40px 0 0 0;
        line-height: 150%;
        font-weight: 600;
        text-shadow: 0 2px 4px rgba(0,0,0,.2);

        @media (max-width: 959px) {
            font-size: 20px; } }

    .banner-buttons {
        display: flex;
        margin-top: 16px;

        @media (max-width: 959px) {
            flex-direction: column; }

        a {
            border-radius: 8px;
            height: 54px;
            background-color: $green;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            font-size: 18px;
            font-family: $quicksand;
            font-weight: bold;

            &:not(:first-child) {
                margin-left: 8px;

                @media (max-width: 959px) {
                    margin: 8px 0 0 0; } } } } }
