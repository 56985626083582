@import 'utils/_variables';

#support {
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-top: 50px;

    h2 {
        font-size: 40px;
        color: $green;
        text-align: center;
        font-weight: bold;
        margin-bottom: 75px;

        @media (max-width: $sm-max) {
            font-size: 24px; } }

    h3 {
        font-size: 26px;
        color: $green;
        font-weight: bold;

        @media (max-width: $sm-max) {
            font-size: 16px; } }

    ul {
        font-size: 20px;

        @media (max-width: $sm-max) {
            font-size: 14px; } }

    .btn {
        align-self: center;
        height: 60px;
        background: #989898 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #D1D5DF80;
        border-radius: 5px;
        border: none;
        color: #FFFFFF;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }

    .btn-materials {
        font-weight: bold;
        font-size: 25px;

        @media (max-width: $sm-max) {
            font-size: 16px; } }

    .btn-back {
        max-width: 200px;
        margin: 20px 0; }

    #main, #search {
        display: flex;
        justify-content: center;
        flex-flow: column; }

    #search {
        padding: 25px 20px 50px;

        * {
            font-family: $quicksand; }

        #search-input {
            height: 60px;
            border: 1px solid #6ab42d;
            border-radius: 5px;
            box-shadow: 0px 10px 20px #D1D5DF80;
            display: flex;
            width: 100%;

            input {
                color: #D3D3D3;
                font-size: 28px;
                font-weight: bold;
                transition: none;
                border: none;
                margin: 0;
                padding: 0 10px;
                height: 100%;
                min-width: 40%;
                text-overflow: ellipsis;
                overflow: hidden;

                @media (max-width: $sm-max) {
                    font-size: 18px; }

                &:focus {
                    color: #666666;
                    outline-style: none;
                    box-shadow: none;
                    border-color: transparent; }

                &::placeholder {
                    opacity: 1; } }

            button {
                border: none;
                background: none;
                padding-right: 10px; }

            button.btn-search {
                margin-left: 4px; }

            button.btn-clear-search-input {
                padding-left: 0; } }

        #search-input.showing-main-section {
            input {
                color: #6ab42d;
                &:focus {
                    color: #6ab42d; } } } }

    #main {
        * {
            font-family: $quicksand; }

        #questions-main-categories {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 20px; } }

    #questions-subcategory {
        padding-top: 0;
        flex-flow: column; }

    #videos-manuals {
        display: flex;
        flex-flow: column;

        * {
            font-family: $quicksand; }

        .support-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 20px; } } }

