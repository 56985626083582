@import 'utils/_mixins';
@import 'utils/_variables';

#blog-post {
    background-color: #fff;
    padding: 40px 30px 0;

    article {
        max-width: 900px;
        margin: 0 auto;

        h1 {
            font-size: 40px;
            color: $green;
            font-family: $quicksand;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 40px;

            @media (max-width: $sm-max) {
                font-size: 22px; } }

        h3 {
            font-size: 22px;
            margin-top: 30px;
            line-height: 150%;

            @media (max-width: $sm-max) {
                font-size: 16px;
                line-height: 120%; } }

        .xs-screen-wrap {
            @media (max-width: $xxs) {
                flex-wrap: wrap; } }

        .xs-screen-order-2 {
            @media (max-width: $xxs) {
                order: 2; } }

        .lg-max-width-360 {
            @media (min-width: $sm) {
                max-width: 360px; } }

        .question {
            font-size: 20px;
            color: #333; }

        .justified {
            text-align: justify; }

        .centered {
            text-align: center; }

        .no-space {
            margin-bottom: 0; }

        .posted {
            align-items: center;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-style: italic;
            margin-top: 20px;
            margin-bottom: 40px;
            border-top: 1px solid $green;
            padding-top: 8px; }

        .description {
            & > a, {
                font-size: 22px;
                margin: 0;
                color: #6AB42D;

                @media (max-width: $sm-max) {
                    font-size: inherit; } } }

        img {
            max-height: 500px;
            max-width: 100%;
            border-radius: 22px; }

        p {
            margin-bottom: 24px;
            line-height: 150%;

            a {
                margin: 0;
                @media (max-width: $sm-max) {
                    font-size: 12px; } }

            strong, em, li, b, span {
                @media (max-width: $sm-max) {
                    font-size: 12px; } }

            img {
              text-align: center;
              display: flex;
              justify-content: center; }

            @media (min-width: 960px) {
                font-size: 18px;

                .figure-caption {
                    font-size: 16px;
                    font-style: italic;
                    margin: 10px 0; } }

            /* Specifics */
            span.darker {
                font-family: $quicksand;
                color: #222;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;

                @media (min-width: 960px) {
                    font-size: 22px;
                    line-height: 150%; } }

            h3.dark-green {
                font-size: 18px;
                line-height: 120%;
                font-weight: 600;

                @media (min-width: 960px) {
                    font-size: 26px;
                    line-height: 150%; } }

            h3.green {
                color: $green;
                font-family: $quicksand;
                font-weight: bold;
                font-size: 30px; }

            span.green-highlight {
                margin: 0;
                font-size: 12px;
                display: inline;
                color: $dark-green;

                @media (min-width: 960px) {
                    font-size: 18px; } }

            .blog-post-30 {
                display: flex;
                position: relative;

                @media (max-width: $sm) {
                    flex-wrap: wrap; }

                a.energy-tip-link {
                    font-size: 12px;
                    color: $green;

                    &:hover {
                        color: $dark-green; }

                    @media (min-width: 960px) {
                        font-size: 18px; } }

                p.smaller {
                    max-width: 82%;

                    @media (min-width: 960px) {
                        max-width: 75%; }

                    @media (max-width: $sm) {
                        max-width: 100%; } }

                .energy-tip-container {
                    position: relative;
                    min-width: 45%;
                    margin-left: 20px;
                    margin-top: -35px;

                    @media (min-width: $sm) {
                        &.higher {
                            margin-top: -60px; } }

                    @media (max-width: $sm-max) {
                        min-width: 35%; }

                    @media (max-width: $sm) {
                        margin: 0; }

                    .energy-tip {
                        position: absolute;
                        width: calc(100% - 25px);
                        top: 25px;
                        left: 25px;
                        padding: 10px 15px 10px 25px;
                        border: 1px solid $green;

                        @media (max-width: $sm) {
                            position: relative;
                            margin-left: 25px;
                            width: unset;
                            top: 0;
                            left: 0; }

                        span.tip-label {
                            font-size: 12px;
                            color: $green;

                            @media (min-width: 960px) {
                                font-size: 18px; } } }

                    .energy-tip-img {
                        position: absolute;

                        @media (max-width: $sm) {
                            top: -20px; } } }

                .energy-tip-phone {
                    position: absolute;
                    right: 0;
                    width: 200px;

                    @media (max-width: 960px) {
                        width: 100px; }

                    @media (max-width: $sm) {
                        display: none; } }

                .energy-tip-phone-second {
                    display: none;

                    @media (max-width: $sm) {
                        display: block;
                        margin: 0 auto;
                        width: 100px; } } } }


        ul {
            list-style: disc; }

        ul, ol {
            padding-left: 18px;

            &.with-margin-list {
                font-size: 18px;
                margin-left: 40px;

                li:not(:last-child) {
                    padding-bottom: 10px; }

                @media (max-width: $sm-max) {
                    line-height: 18px;
                    font-size: 12px;
                    margin-left: 20px; } }

            @media (min-width: $xs) {
                &.items-padded-10 {
                    li:not(:last-child) {
                        padding-bottom: 10px; } }

                &.items-padded-20 {
                    li:not(:last-child) {
                        padding-bottom: 20px; } }

                &.items-padded-30 {
                    li:not(:last-child) {
                        padding-bottom: 30px; } }

                &.items-padded-40 {
                    li:not(:last-child) {
                        padding-bottom: 40px; } } } }



        .tags {
            margin: 30px 0; }
        a, span {
            display: inline-block;
            font-size: 16px;
            margin: 0 10px 5px 0;

            &.button {
                font-size: 12px;
                font-family: "Quicksand", sans-serif;
                font-weight: 700;
                padding: 20px 16px;
                vertical-align: middle;
                border-radius: 4px;
                background: $yellow;
                color: $white;
                padding: 15px 17px;

                @media (min-width: 960px) {
                    font-size: 18px;
                    padding: 20px 24px; }

                &:hover {
                    background: $dark-yellow;
                    color: $white;
                    box-shadow: 0px 3px 6px #00000029; } } }

        span.quotation {
            font-style: italic;
            display: inline;
            font-size: 18px;
            @media (max-width: $sm-max) {
                line-height: 18px;
                font-size: 12px; } }

        a.dark-green {
            font-size: 12px;
            color: #487a1f;
            @media (min-width: 960px) {
                font-size: 18px; } }

        p.figure-caption a.dark-green {
            @media (min-width: 960px) {
                font-size: 16px; } }

        a:not(.button):hover {
            color: $green; }

        span {
            font-style: normal; }

        blockquote.citation {
            background: #f9f9f9;
            border-left: 10px solid #ccc;
            margin: 0.5em 10px 1.5em;
            padding: 0.5em 10px;
            quotes: "\201C""\201D";

            &:before {
                color: #ccc;
                content: open-quote;
                font-size: 4em;
                line-height: 0.1em;
                margin-right: 0.1em;
                vertical-align: -0.4em; }

            .citation-paragraph {
                display: inline;
                padding-left: 1.6rem;

                @media (min-width: 960px) {
                    padding-left: 2rem; } } } }

    .social-sharing {
        align-items: center;
        display: flex;

        p {
            color: $green;
            font-size: 15px;
            font-weight: 900;
            text-transform: uppercase;
            font-style: normal;
            margin: 0; }

        a {
            font-size: 20px;
            margin: 0 0 0 8px;

            svg {
                fill: $green;
                stroke: none; } } }

    .simple-list {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 0.2em 1em;

        @media (min-width: 960px) {
            grid-template-columns: 1fr 2fr;
            font-size: 18px; }

        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.url {
                align-items: center;
                text-align: center;
                border-right: 0.1em solid rgba(236, 19, 19, 0.61);
                min-height: 4em; } } }

    & strong {
        font-size: inherit;
        @media (max-width: $sm-max) {
              font-size: inherit; } } }
