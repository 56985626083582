@import 'utils/_variables';

.short-banner {
    height: 320px;
    background-color: #555;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;

    @media (max-width: $sm-max) {
        height: 200px; }

    .short-banner-wrap {
        padding-left: 10vw;

        h1 {
            font-family: $quicksand;
            font-size: 60px;
            line-height: 120%;
            font-weight: 700;
            color: #fff;
            text-shadow: 0 2px 4px rgba(0,0,0,.5);

            @media (max-width: $sm-max) {
                font-size: 24px; } } } }
