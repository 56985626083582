@import 'utils/_variables';

.case-study {
    padding: 120px 0 105px;

    @media (max-width: 959px) {
        padding: 5.5rem 2rem; }

    .container {
        display: block; }

    h1 {
        font-family: $quicksand;
        font-size: 60px;
        color: $green;
        text-align: center;

        @media (max-width: 959px) {
            font-size: 40px; } }

    .case-item {
        margin-top: 100px;
        display: flex;
        align-items: center;

        @media (max-width: 959px) {
            margin-top: 4rem;
            flex-direction: column; }

        .case-image {
            flex: 0 0 165px;

            @media (max-width: 959px) {
                flex: unset;
                max-width: 165px; }

            img {
                width: 100%; } }

        .case-text {
            margin-left: 100px;

            @media (max-width: 959px) {
                margin: 2rem 0 0; }

            .case-title {
                color: $green;
                font-family: $quicksand;
                font-size: 40px;

                @media (max-width: 959px) {
                    font-size: 28px; } }

            p {
                margin-top: 20px; } } } }
