.blog-social-wrapper {
    margin-bottom: 20px;

    .fb-page {
        display: block;
        margin-bottom: 25px;
        max-width: 100%;
        width: 100%;
        span, iframe {
            width: 100% !important; } } }
