@import 'utils/_variables';

.commercial-potential {
    max-width: $lg;
    margin: 0 auto;
    padding: 90px 30px;

    @media (max-width: 959px) {
        padding: 45px 30px; }

    h2 {
        font-family: $quicksand;
        font-size: 40px;
        color: $green;
        text-align: center;
        font-weight: bold;

        @media (max-width: 959px) {
            font-size: 30px; } }

    .commercial-items {
        display: flex;
        margin: 80px -15px 0;

        @media (max-width: 959px) {
            margin: 40px 0 0;
            flex-direction: column; }

        .item {
            display: flex;
            flex-direction: column;
            flex: 0 0 calc(25% - 30px);
            margin: 0 15px;

            @media (max-width: 959px) {
                margin: 0;
                flex-basis: 100%;

                &:not(:first-child) {
                    margin-top: 30px; } }

            .icon {
                height: 80%;
                display: flex;
                justify-content: center;
                border: 1px solid $green;
                border-radius: 22px;
                padding: 33px 33px 66px;

                svg {
                    height: 90px; } }

            .text {
                height: 100%;
                box-shadow: 0 4px 24px rgba(0,0,0,.2);
                margin-top: -33px;
                padding: 30px 20px;
                background-color: #ccc;
                text-align: center;
                font-size: 16px;
                line-height: 150%;
                border-radius: 8px; } } } }
