@import 'utils/_mixins';
@import 'utils/_variables';

// Header
header {
    background: $white;
    display: flex;
    height: $header-height;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);

    @media (max-width: $lg) {
        height: 130px; }

    @include media-max-width($xxs) {
        height: $header-height - 30; }

    .container {
        @include media-max-width($sm-max) {
            max-width: none; }
        @include media-min-width($md) {
            padding: 0 15px;
            position: relative;
            justify-content: space-between; } }

    > .container > a {
        align-self: center;
        max-width: 250px;
        width: 70%;
        img {
            display: block;
            max-width: 100%; }

        @include media-width-between(349px, $xs) {
            max-width: 150px; }
        @include media-min-width($lg) {
            margin-top: 0;
            max-width: 260px;
            padding-left: 25px; }

        // Call to action
        &.request-a-demo {
            // display: inline-block
            color: $white;
            background: $green;
            border-radius: 2px;
            font: 22px $roboto;
            padding: 15px 10px;
            vertical-align: middle;
            width: auto;

            @include media-max-width(349px) {
                display: none; }
            @include media-min-width(349px) {
                display: block;
                font-size: 14px;
                padding: 15px 5px;
                position: absolute;
                right: 20px; }
            @include media-width-between($xs, $xs-max) {
                font-size: 16px; }
            @include media-min-width($sm) {
                font-size: 24px;
                padding: 15px 5px; }
            @include media-min-width($md) {
                display: inline-block;
                font-size: 18px;
                margin-left: 50px;
                margin-right: -25px;
                position: relative; }
            @include media-min-width($lg) {
                font-size: 24px;
                margin-left: 70px; } } }

    // Toggle Mobile Menu
    button {
        align-self: center;
        background: 0;
        border: 0;
        height: 50px;
        margin: 0 10px;
        padding: 0;
        width: 50px;
        @include transition(.33s);
        @include media-min-width($md) {
            display: none; }

        span {
            background: $green;
            display: block;
            height: 6px;
            margin: 5px auto;
            width: 30px;
            @include transition(.33s); } }

    &.active {
        button {
            transform: rotate(-180deg); }
        span {
            &:first-child {
                transform: rotate(45deg); }
            &:nth-child(2) {
                opacity: 0; }
            &:last-child {
                margin-top: -22px;
                transform: rotate(-45deg); } }
        nav {
            left: 0; } } }

// Nav

.header-submenu {
    opacity: 1 !important;
    nav {
        a {
            display: block;
            color: white;
            background-color: $dark-green;
            border-radius: 0;

            &:first-of-type {
                border-radius: 4px 4px 0 0; }
            &:last-of-type {
                border-radius: 0 0 4px 4px; }

            &:hover, &.active {
                background-color: #5CA124 !important; } } } }

nav {
    @include media-min-width($md) {
        align-self: center; }

    @include media-max-width($sm-max) {
        bottom: 0;
        left: -100%;
        position: fixed;
        top: 70px;
        width: 100%;
        @include transition(.33s);

        div, p, a {
            background: $white; }
        div > a {
            background: none; }
        div {
            padding-top: 15px; } }
    @include media-min-width($md) {
        div {
            display: inline-block; } }

    p, a {
        color: $dark-green;
        display: inline-block;
        font: 18px $quicksand;
        font-weight: 700;
        padding: 20px 16px;
        vertical-align: middle;
        border-radius: 4px;
        margin: 0 4px;

        @include media-max-width($sm-max) {
            background-color: #f1f1f1;
            display: block;
            font-size: 16px;
            padding: 20px;
            border-bottom: 1px solid $green;
            font-family: $quicksand;

            &:last-child {
                border-bottom: none; } }

        @include media-width-between($md, $md-max) {
            font-size: 16px; } }

    .blog {
        color: $light-grey;

        @include media-max-width($sm-max) {
            color: $green; } }

    p, .hidden {
        display: none;
        @include media-max-width($sm-max) {
            display: block; } }

    a {
        &:not(.lang):hover {
            background: $dark-green;
            color: $white; }
        &:not(.lang).active {
            background: $dark-green;
            color: $white; }
        &:not(.lang).active.blog {
            border: 1px solid $light-grey; } }

    a.call-to-action {
        background: $yellow;
        color: $white;
        padding: 20px 24px;
        &:not(.lang):hover {
            background: $dark-yellow;
            color: $white; }
        &:not(.lang).active {
            background: $yellow;
            color: $white; } } }
