@import 'utils/_variables';

.awards {
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 80%;

    .box {
        flex: 1 0 calc(50% - 10px);
        display: flex;
        flex-wrap: wrap;
        border: 2px solid #CCCCCC;
        border-radius: 8px;
        padding: 30px 15px;
        justify-content: space-around;
        align-items: center;

        @media (max-width: $sm-max) {
            flex-direction: column;
            max-height: max-content; }

        img {
            padding: 5px;
            max-width: 220px;

            @media (max-width: $sm-max) {
                margin: 10px 0; } } } }
