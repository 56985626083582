.follow-us-link {
    display: inline-block;
    width: 30px;
    text-align: center;

    &:not(:first-child) {
        margin-left: 20px; }

    svg {
        fill: #fff;
        width: 100%; }

    &:hover {
        transform: translateY(-3px); } }
