@import 'utils/_variables';

.category {
    flex: 1 0 40%;
    max-width: 46%;
    min-width: 300px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

    .title-with-icon {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        h3 {
            text-transform: uppercase;
            margin-bottom: 0px;
            padding-left: 10px; }

        img {
            height: 30px; } }

    .subcategories {
        flex-grow: 1;
        border: 1px solid $light-grey;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 30px;

        ul li {
            list-style-type: none;
            padding: 10px 0;

            &:hover, a:hover {
                cursor: pointer;
                color: $green; } } } }
