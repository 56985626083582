@import 'utils/_variables';

.big-banner {
    height: 600px;
    background-color: #555;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $sm-max) {
        height: 160px; }

    &.mobile-banner {
        display: none;
        @media (max-width: $sm-max) {
            display: flex; } }

    &.hide-in-mobile {
        display: flex;
        @media (max-width: $sm-max) {
            display: none; } }

    .big-banner-wrap {
        display: flex;
        flex-flow: column;
        width: 980px;

        h1, h2.h1 {
            font-family: $quicksand;
            font-size: 50px;
            line-height: 1.5;
            font-weight: 700;
            color: #fff;
            width: 360px;
            margin-bottom: 30px;

            @media (max-width: $sm-max) {
                font-size: 24px;
                margin-bottom: 0; } }

        h2:not(.h1) {
            font-family: $roboto;
            font-size: 42px;
            line-height: 70px;
            color: $white;
            font-weight: 400;

            @media (max-width: $sm-max) {
                font-size: 16px;
                margin-bottom: 0;
                line-height: 60px; } }

        p {
            font-family: $roboto;
            font-size: 28px;
            line-height: 40px;
            color: #fff;
            margin-bottom: 0px;

            @media (max-width: $sm-max) {
                font-size: 12px; } }

        &.single-centred-column {
            align-items: center;
            justify-content: center;
            height: 100%;
            max-width: 100%;

            h1, h2.h1 {
                width: auto;
                margin-bottom: 40px;

                @media (max-width: $sm-max) {
                    margin-bottom: 10px; } }

            > div {
                margin-bottom: 60px;

                @media (max-width: $sm-max) {
                    margin-bottom: 0; } }

            p {
                @media (max-width: $sm-max) {
                    margin-bottom: 2px;
                    line-height: 20px; } }

            p, h1, h2.h1 {
                text-align: center; }

            img {
                @media (max-width: $sm-max) {
                    display: none; } } } } }


