@mixin transition($seconds:".33s", $delay:"0s", $property:"all", $function:"ease-in-out") {
    transition: #{$property} #{$seconds} #{$function} #{$delay}; }

@mixin media-min-width($width) {
    @media screen and (min-width: $width) {
        @content; } }

@mixin media-max-width($width) {
    @media screen and (max-width: $width) {
        @content; } }

@mixin media-width-between($start, $end) {
    @media screen and (min-width: $start) and (max-width: $end) {
      @content; } }

@mixin background-orange-gradient {
    background: linear-gradient(#ff9f86, #feaa6c); }
