@import 'utils/_mixins';
@import 'utils/_variables';


#blog-comment {
    margin-bottom: 25px;

    h3 {
        margin: 10px 0 30px; }
    .col {
        @include media-max-width($xs-max) {
            padding: 0; }
        @include media-min-width($sm) {
            &:first-child {
                padding-left: 0; }
            &:nth-child(2) {
                padding-right: 0; }
            &:nth-child(3) {
                padding: 0; } } }

    textarea {
        padding-bottom: 8px;
        padding-top: 8px; }

    button {
        width: 100%; } }
