@import 'utils/_variables';

.__utilities-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2, h3, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    letter-spacing: 3px;
    margin-bottom: 20px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 32px;
      line-height: 40px;
      color: $dark-grey-2; } }

  ul {
    list-style: none; }

  p {
    font-family: $helvetica; }

  nav {
    margin-top: 82px;

    a, button {
      border: 3px solid $dark-green;
      border-radius: 5px;
      outline: none;
      padding: 26px 50px;
      font-size: 24px;
      line-height: 22px;

      &:hover {
        background-color: $dark-green;
        color: #FFFFFF; } } } }

.__utilities-header {
  background: transparent url('/img/utilities/banner.jpg') 0% 0% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $sm-max) {
    height: 330px; }

  p {
    color: #FFFFFF;
    font-family: $helvetica;
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
    width: 643px;
    text-align: center;
    margin-top: 40px;

    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 20px;
      margin-top: 20px;
      width: 285px; } }

  >section {
    display: flex;
    justify-content: space-evenly;
    width: 745px;
    margin-top: 66.14px;

    @media (max-width: $sm-max) {
      flex-direction: column;
      width: 215px;
      margin-top: 0; }

    .__header-row {
      display: flex;
      justify-content: space-around;
      width: 50%;

      @media (max-width: $sm-max) {
        justify-content: space-evenly;
        width: 100%;

        + .__header-row {
          margin-top: 20px; } }

      figure {
        @media (max-width: $sm-max) {
          display: flex;
          width: 50%;
          align-items: self-start;
          justify-content: center; }

        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 74.35px;
            max-height: 71.07px;
            width: auto;
            height: auto; } } } } } }

.__utilities-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $sm-max) {
    margin: 50px 0; }

  h2 {
    @media (max-width: $sm-max) {
      width: 198px; } }

  &#consumer-goals-evolving {
    .consumer-goals-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .consumer-goals-mobile {
      display: none;
      @media (max-width: $sm-max) {
        display: block; } }

    p {
      font-size: 24px;
      line-height: 40px;
      width: 988px;
      margin-bottom: 60px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        width: 300px; } } }


  &#reinvent-themselves {
    @media (max-width: $sm-max) {
      margin-bottom: 30px; }

    p {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 60px; }

    p.subtitle {
      width: 978px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        width: 303.31px; } }

    .__utilities-article-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 1068px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        justify-content: center;
        width: 303.31px; }

      article {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 307px;

        @media (max-width: $sm-max) {
          width: 303.31px; }

        &:nth-last-of-type(1) {
          section {
            h3 {
              @media (max-width: $sm-max) {
                width: 120px; } } } }

        >section {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: $sm-max) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%; }

          figure {
            margin-top: 25.36px;

            @media (max-width: $sm-max) {
              margin-top: 0; }

            img {
              @media (max-width: $sm-max) {
                display: block;
                max-width: 61.34px;
                max-height: 60.38px;
                width: auto;
                height: auto; } } }

          h3 {
            color: $dark-grey-2;
            font-size: 32px;
            line-height: 40px;

            @media (max-width: $sm-max) {
              font-size: 18px;
              line-height: 22px;
              text-align: left;
              margin-left: 10.31px; } } }

        p {
          margin-top: 44.28px;

          @media (max-width: $sm-max) {
            font-size: 12px;
            line-height: 18px;
            margin-top: -10px;
            margin-left: 35px;
            width: 198px; } } } } }

  &#iris-by-voltaware {
    margin-top: 0;

    .iris-chart-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .iris-chart-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } }

    p {
      font-size: 20px;
      line-height: 40px;
      width: 1010px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        width: 273.67px; } } }


  &#implementing-iris {
    h2 {
      margin-bottom: 60px;

      @media (max-width: $sm-max) {
        margin-bottom: 50px; } }

    >section {
      display: flex;
      justify-content: space-between;
      width: 1159px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        width: 303.31px; }

      article {
        width: 335px;

        &:nth-of-type(2) {
          width: 304px; }

        @media (max-width: $sm-max) {
          display: flex;
          flex-direction: column;
          width: 100%; }


        >section {
          display: flex;

          figure {
            @media (max-width: $sm-max) {
              width: 60.01px; }

            img {
              @media (max-width: $sm-max) {
                display: block;
                max-width: 60.01px;
                max-height: 60.01px;
                width: auto;
                height: auto; } } }


          h3 {
            font-size: 30px;
            line-height: 32px;
            margin-left: 25.88px;

            @media (max-width: $sm-max) {
              font-size: 18px;
              line-height: 22px;
              text-align: left;
              margin-left: 15px;
              width: 132px; } } } }

      p {
        font-size: 20px;
        line-height: 34px;
        margin-top: 30.9px;

        @media (max-width: $sm-max) {
          font-size: 12px;
          line-height: 18px;
          margin-top: -10px;
          margin-left: 75px;
          width: 198px; } } } }

  &#how-it-works {
    .how-it-works-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .how-it-works-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } } }

  &#innovative-utilities {
    >section {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: space-evenly;
      width: 928px;

      @media (max-width: $sm-max) {
        width: 350px; }

      figure {
        @media (max-width: $sm-max) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 162px;
          height: 76px; }

        img {
          max-width: 162px;
          max-height: 76px;

          @media (max-width: $sm-max) {
            display: block;
            width: auto;
            height: auto; } } }


      figure + figure {
        margin-left: 35px;

        @media (max-width: $sm-max) {
          margin-left: 0px; } } } }


  &#get-in-touch {
    @media (max-width: $sm-max) {
      width: 303.31px; }

    div {
      display: flex;

      @media (max-width: $sm-max) {
        flex-direction: column; }

      a + a {
        margin-left: 30px;

        @media (max-width: $sm-max) {
          margin-left: 0px;
          margin-top: 30px; } } }


    p {
      font-size: 20px;
      line-height: 40px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 40px; } } } }
