@import 'utils/_variables';

.__amazon-shop-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2, h3, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    letter-spacing: 3px;
    margin-bottom: 20px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 26px;
      line-height: 40px;
      color: $dark-grey-2;

      @media (max-width: $sm-max) {
        font-size: 16px;
        line-height: 24px; } } }

  ul {
    list-style: none; }

  p, li {
    font-family: $helvetica;
    font-size: 20px;
    line-height: 40px;
    color: $dark-grey;

    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 18px; } }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      line-height: 24px;
      padding: 12px 40.5px; } }

  br {
    display: none;

    @media (max-width: $sm-max) {
      display: block; } }

  section {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background: #FFFFFF;

    article {
      max-width: 1200px; }

    &:nth-child(odd) {
      background: #F6F6F9; }

    &:first-child {
      background: transparent url('/img/technology/background-amazon-shop.png') 100% 50% no-repeat padding-box;
      background-size: cover;
      width: 100%;
      height: 550px;

      @media (max-width: $sm-max) {
        background: transparent url('/img/technology/background-amazon-shop.png') 45% 50% no-repeat padding-box;
        height: unset; } }

    &.strip::after {
      content: '';
      position: absolute;
      top: 20%;
      width: 100%;
      height: 18rem;
      background-color: #6AB42D;

      @media (max-width: $sm-max) {
        height: 19rem; } } } }

.__amazon-shop-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    align-items: center;
    height: 200px; }

  .mobile {
    display: none;

    @media (max-width: $sm-max) {
      display: block;
      height: 100%;
      margin-bottom: -30px;
      margin-left: -30px;

      img {
        width: 150px; } } }

  .elements {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    br {
      display: initial; }

    .phone {
      margin-bottom: -10px; }

    @media (max-width: $sm-max) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      padding: 20px;
      align-items: flex-start;

      .phone {
        display: none; }

      .amazon-logo {
        margin-top: 30px;

        img {
          width: 100px; } } } } }

.__amazon-shop-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  background: #FFFFFF;

  @media (max-width: $sm-max) {
    flex-direction: column;
    align-items: center;
    padding: 25px 0; }

  .voltaware-home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin-left: 40px;

    .voltaware-home {
      max-width: 500px; }

    @media (max-width: $sm-max) {
      margin: 0; }

    .voltaware-home {
      @media (max-width: $sm-max) {
        width: 300px; } } }

  .voltaware-home-action-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 1;

    @media (max-width: $sm-max) {
      padding: 0;
      width: 100%; }

    h2 {
      line-height: 60px;
      text-align: left;
      color: #FFFFFF;
      margin-top: 150px;

      @media (max-width: $sm-max) {
        margin: 0;
        padding: 0 10px;
        line-height: 24px;
        font-size: 20px; } }

    .order-btn {
      background: #6AB42D;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 700;
      margin-top: 60px;
      max-width: 500px;

      &:hover {
        background: #4B8C2A; }

      img {
        width: 150px;
        margin: 10px 0 0 10px; }

      @media (max-width: $sm-max) {
        margin: 20px 10px 0;
        font-size: 20px;

        img {
          width: 100px; } } }

    .purchase {
      margin-top: 30px;
      color: #666666;
      font-size: 36px;
      font-weight: 700;
      font-family: 'Inter', 'Arial', sans-serif;

      @media (max-width: $sm-max) {
        font-size: 26px;
        margin-left: 10px; } }

    .price {
      color: #6AB42D;
      font-size: 60px;
      font-weight: 700;
      font-family: 'Inter', 'Arial', sans-serif;

      span {
        font-weight: 400;
        font-size: 26px;
        color: #666666;
        font-style: italic;
        text-decoration: line-through;
        vertical-align: text-top; }

      @media (max-width: $sm-max) {
        font-size: 30px;
        padding-left: 10px;

        span {
          font-size: 20px; } } } } }

.__features-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 0;
  background: #F6F6F9;

  @media (max-width: $sm-max) {
    flex-direction: column; }

  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    img {
      margin: 0 10px 20px; }

    strong {
      font-size: 26px;
      font-weight: 600;
      color: #666;
      padding-bottom: 20px; }

    p {
      max-width: 380px;
      font-size: 22px;
      text-align: center; }

    @media (max-width: $sm-max) {
      img {
        width: 300px; }

      strong {
        font-size: 24px; }

      p {
        font-size: 20px;
        text-align: center;
        max-width: unset;
        line-height: 28px;
        padding: 0 10px; } } } }

.__how-it-works-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 0;
  background: #FFFFFF;

  .content {
    display: flex;
    flex-direction: column;

    img {
      width: 70%;
      margin: 0 auto 30px; } }

  @media (max-width: $sm-max) {
    .content {
      max-width: 100%;
      padding: 0 10px;
      text-align: justify;

      img {
        width: 100%; }

      p {
        font-size: 16px; } } } }

.__easy-installation-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 0;
  background: #F6F6F9;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      list-style: initial;
      padding: 100px 0 0 40px;

      li {
        font-family: "Quicksand", sans-serif;
        font-weight: 700;
        font-size: 28px;
        color: #666;
        padding-bottom: 6rem;

        &:last-child {
          padding-bottom: 0; } } } }

  @media (max-width: $sm-max) {
    .content {
      flex-direction: column;

      img {
        width: 300px; }

      ul {
        padding-top: 20px;

        li {
          padding-bottom: 1rem;
          font-size: 20px;
          line-height: 24px; } } } } }
