@import 'utils/_mixins';
@import 'utils/_variables';


#help-colors-component {
    > div:not(.help-colors-info) {
        margin-bottom: 30px;
        padding-left: 40px;

        @include media-min-width($sm) {
            &:nth-child(1) {
                order: 1; }
            &:nth-child(2) {
                order: 3; }
            &:nth-child(3) {
                order: 5; }
            &:nth-child(4) {
                order: 2; }
            &:nth-child(5) {
                order: 4; }
            &:nth-child(6) {
                order: 6; } } }

    h3 {
        font-size: 30px;
        position: relative;
        &::before {
            border-radius: 50%;
            content: '';
            height: 16px;
            left: -25px;
            position: absolute;
            top: 8px;
            width: 16px; }
        &.blue::before {
            background: $sensor-blue; }
        &.red::before {
            background: $sensor-red; }
        &.yellow::before {
            background: $sensor-yellow; }
        &.green::before {
            background: $sensor-green; } }

    .help-colors-info {
        @include media-min-width($sm) {
            left: 50%;
            position: relative;
            order: 7;
            @include media-max-width($sm-max) {
                padding-right: 50px; } } } }
