@import 'utils/_mixins';
@import 'utils/_variables';


#subscribe-component {
    .loading {
        display: block;
        margin: 0 auto; }

    form {
        display: flex;
        margin: 10px 0;
        @include media-max-width($xs-max) {
            justify-content: space-between; } }

    input {
        border: 1px solid $green;
        flex-grow: 1;
        height: auto;
        margin: 0 5px 0 0;
        max-width: 680px;
        padding-bottom: 6px;
        padding-top: 6px;

        @include media-max-width($xs-max) {
            max-width: 50%; } }

    button {
        width: 40%;
        border-style: none;
        @include media-min-width($sm) {
            width: 60%; } } }
