@import 'utils/_variables';


.blog-categories-wrapper {
    margin-bottom: 25px;
    li {
        margin-bottom: 8px;
        padding-left: 13px;
        position: relative;
        &:last-child {
            margin-bottom: 0; }
        &::before {
            background: $green;
            border-radius: 50%;
            content: '';
            height: 6px;
            left: 0;
            position: absolute;
            top: 5px;
            width: 6px; } }
    a {
        font-size: 15px;
        &:hover {
            color: $green; } } }
