@import 'utils/_mixins';
@import 'utils/_variables';

#slider-blog {
    max-width: 1200px;
    margin: 0 auto;
    height: 400px;
    padding-bottom: 40px;

    @media (max-width: 959px) {
        margin: 0 -40px; }

    .slick-list, .slick-track, .slick-slider {
        height: 100%; }

    .slick-list {
        border-radius: 22px;
        overflow: hidden;

        @media (max-width: 959px) {
            border-radius: 0; } }

    .slick-slide {
        width: 100%;
        height: 100%;
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,.4);
            z-index: 5; } }

    .slide-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        color: #fff;
        padding-bottom: 60px;

        * {
            max-width: 80%;
            margin: 0 auto;
            text-shadow: 0 4px 16px rgba(0,0,0,.3); }

        .slide-title {
            font-family: $quicksand;
            font-size: 32px;
            color: #fff;
            font-weight: bold;

            @media (max-width: 959px) {
                font-size: 24px; } }

        .slide-preview {
            text-align: left;

            p {
                color: #fff;
                font-size: 22px;
                margin: 16px 0 0 0;
                max-width: 100%;
                line-height: 120%;

                @media (max-width: 959px) {
                    font-size: 16px; } } } }

    .slide-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%; }

    .slick-dots {
        bottom: 10px;

        li button:before {
            color: #fff;
            font-size: 12px; } } }
