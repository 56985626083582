.business-solutions-icon {
    margin-bottom: 20px;

    h5 {
        font-size: 18px;
        max-width: 150px;
        word-break: break-word;
        img {
            display: block;
            margin-bottom: 20px;
            max-width: 80px; } } }
