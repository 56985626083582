@import 'utils/_mixins';
@import 'utils/_variables';


.lading_link {
    display: block;
    max-width: 100%;

    img {
        max-width: 100%; } }

.business-item-component {
    .title {
        padding-right: 50px;
        position: relative;
        img {
            margin-top: -25px;
            position: absolute;
            right: 42%;
            top: 50%;
            width: 40px;
            @include media-max-width($md) {
                right: 0; } } } }


@include media-min-width($sm) {
    .business-item-partners:not(.multiple) {
        text-align: right; } }


.business-item-partners {
    a {
        display: inline-block;
        margin-bottom: 15px;
        vertical-align: middle;
        &:hover {
            transform: scale(1.06); } } }

.col.sm-12.business-item-partners,
.col.sm-12.business-item-partners.multiple {
    text-align: left;
    img {
        max-width: 200px; } }

.business-item-partners.multiple {
    align-items: center;
    justify-content: center;
    img {
        max-width: 50%;
        min-width: 150px;
        padding: 0 10px;
        @include media-min-width($xs) {
            max-width: 33.3333333%; }
        @include media-min-width($sm) {
            max-width: 175px; }
        @include media-min-width($md) {
            max-width: 230px; }
        @include media-min-width($lg) {
            max-width: 250px;
            max-height: 130px; } } }
