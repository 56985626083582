@import 'utils/_mixins';
@import 'utils/_variables';


#residential .section.container:first-child {
    padding-bottom: 0; }

.residential-item {
    margin-bottom: 50px;

    img {
        display: block;
        margin-bottom: 25px;
        max-width: 100%;
        @include media-min-width($xs) {
            max-width: 400px; }
        @include media-min-width($sm) {
            max-width: 100%; }
        @include media-min-width($sm) {
            margin-left: auto;
            margin-right: auto; } }

    &:nth-child(4) {
        img {
            display: inline; } }

    h6 {
        color: $green;
        font-size: 48px;
        margin-bottom: 5px; }

    p,
    ul {
        margin-bottom: 10px;
        @include media-min-width($xs) {
            padding-left: 25px; } }

    ul {
        display: block;
        li {
            display: block;
            font: 14px sans-serif;
            margin: 15px 0;
            &::before {
                background: $green;
                border-radius: 50%;
                content: '';
                display: inline-block;
                height: 12px;
                margin: -2px 5px 0 0;
                vertical-align: middle;
                width: 12px; } } }

    @include media-min-width($sm) {
        &:nth-child(1),
        &:nth-child(3) {
            .col:first-child {
                order: 2; }
            .col:last-child {
                order: 1; } } }

    a {
        color: $green;
        text-decoration: underline;
        &:hover {
            color: #519039; } } }
