@import 'utils/_mixins';
@import 'utils/_variables';

.__voltacare-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;

  h1, h2 {
    width: 1100px;

    @media (max-width: $sm-max) {
      margin-bottom: 0; } }

  h1, h2, button {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3, button {
    color: $orange; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 40px;
    line-height: 80px;
    letter-spacing: 3px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    font-weight: bold;

    @media (max-width: $sm-max) {
      margin-bottom: 0; }

    &.dark-grey {
      font-size: 26px;
      line-height: 40px;
      color: $dark-grey-2;

      @media (max-width: $sm-max) {
        font-size: 16px;
        line-height: 24px; } } }

  ul {
    list-style: none; }

  p, li {
    font-family: $roboto;
    font-size: 20px;
    line-height: 40px;
    color: $dark-grey;

    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 18px; } }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      line-height: 24px;
      padding: 12px 40.5px; } }

  br {
    display: none;

    @media (max-width: $sm-max) {
      display: block; } } }


.__voltacare-header {
  @include background-orange-gradient;
  background-size: cover;
  width: 100%;
  height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    align-items: center;
    height: 160px; }

  h1 {
    text-transform: capitalize;
    font-size: 45px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px;
      width: 94%;
      text-align: center;
      margin: auto; } } }

.__voltacare-section {
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.no-padding-top {
    padding-top: 0; }

  &.no-padding-bottom {
    padding-bottom: 0; }

  &.light-grey {
    background-color: $light-grey-2; }

  @media (max-width: $sm-max) {
    padding-top: 40px;
    padding-bottom: 40px; }

  h2 {
    @media (max-width: $sm-max) {
      width: 94%;
      font-size: 22px; } }

  h3.dark-grey {
    font-family: $roboto;
    font-size: 28px;
    line-height: 60px;
    text-align: center;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      width: 94%; } }

  h3.request {
    margin-bottom: 80px;

    @media (max-width: $sm-max) {
      margin-bottom: 30px; } }

  .__section-row {
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin-top: 40px;
    align-items: center;

    @media (max-width: $sm-max) {
      flex-direction: column;
      width: 375px;
      margin-top: 20px;

      &.mobile-reverse {
        flex-direction: column-reverse; } }

    figure {
      @media (max-width: $sm-max) {
        display: flex;
        justify-content: center;
        margin-bottom: 20px; }

      img {
        margin-top: 18px;
        width: 340px;

        @media (max-width: $sm-max) {
          width: 60% !important;
          margin: auto; } } }

    article {
      display: flex;
      flex-direction: column;

      &.margin-left {
        margin-left: 50px; }

      &.margin-right {
        margin-right: 50px; }

      @media (max-width: $sm-max) {
        align-items: center;
        margin: auto !important; }

      p {
        font-family: $roboto;

        @media (max-width: $sm-max) {
          width: 90%; } }


      p + p {
        margin-top: 15px;

        @media (max-width: $sm-max) {
          margin-top: 6px; } }

      div {
        margin-top: 100px;

        a.btn-outlined {
          display: inline-flex; }

        @media (max-width: $sm-max) {
          margin-top: 30px; } } } }

  &#core-of-voltaware {
    @media (max-width: $sm-max) {
      width: 375px; }

    .appliance-fingerprints-analysis-desktop {
      @media (max-width: $sm-max) {
        display: none; } }

    .appliance-fingerprints-analysis-mobile {
      display: none;

      @media (max-width: $sm-max) {
        display: block; } }

    h2 {
      margin-bottom: 60px;

      @media (max-width: $sm-max) {
        width: 256px;
        margin-bottom: 30.67px; } }

    p {
      width: 1120px;

      @media (max-width: $sm-max) {
        width: 303.31px; } }

    figure {
      margin-top: 59.7px;
      margin-bottom: 60.17px;

      img {
        max-width: 340px;

        @media (max-width: $sm-max) {
          max-width: 60%; } } }

    div {
      margin-top: 30px;

      a {
        @media (max-width: $sm-max) {
          line-height: 18px;
          padding: 30px 40.5px; } } } }

  &#automatic-detection {
    .__section-row {
      display: flex;
      align-items: center;

      @media (max-width: $sm-max) {
        flex-direction: column; }

      figure {
        img {
          display: block;
          max-width: 327.5px;
          max-height: 655px;
          width: auto;
          height: auto;

          @media (max-width: $sm-max) {
            max-width: 255px;
            max-height: 510px; } } }

      article {
        display: flex;
        flex-direction: column;

        ul, p {
          margin-left: 50.5px;

          @media (max-width: $sm-max) {
            margin: auto; } }

        p {
          font-size: 20px;
          width: 742px;

          @media (max-width: $sm-max) {
            margin-top: 20px;
            font-size: 12px;
            width: 302px; } }

        p + p {
          margin-top: 10px;

          @media (max-width: $sm-max) {
            margin-top: 20px; } }

        ul {
          list-style-type: '- ';

          li {
            margin-left: 20px;

            @media (max-width: $sm-max) {
              margin: auto;
              width: 283px; } } } } } } }
