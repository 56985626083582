@import 'utils/_mixins';
@import 'utils/_variables';

// CSS Reset
* {
  -webkit-font-smoothing: antialiased; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  font-size: 10px;
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

::selection {
  background: $green;
  color: #fff; }

// Utils
b {
  font-family: $roboto;
  font-weight: 500; }

.flex-wrap {
  display: flex;
  flex-wrap: wrap; }

.text-center {
  text-align: center; }

.img-responsive {
  max-width: 100%; }

.video-wrapper {
  position: relative;
  padding-bottom: 30vh;
  height: 0;

  @include media-min-width($sm);
  padding-bottom: 60vh;

  @include media-min-width($md);
  padding-bottom: 90vh;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30vh;

    @include media-min-width($sm);
    height: 60vh;

    @include media-min-width($md);
    height: calc(100vh - 100px);  // should remove header's height, doesn't work properly with variables

    @include media-max-width($xs-max);
    .hidden-xs-max {
      display: none !important; } } }

// Grid
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  @include media-min-width($sm);
  max-width: $container-sm-width;
  @include media-min-width($md);
  max-width: $container-md-width;
  @include media-min-width($lg);
  max-width: $container-lg-width;

  &.justify-center {
    justify-content: center; } }

.col {
  flex-basis: 100%;
  padding: 0 40px;

  @include media-max-width($xxs);
  &.xxs-2 {
    flex-basis: 16.66667%; }
  &.xxs-4 {
    flex-basis: 33.33333%; }
  &.xxs-6 {
    flex-basis: 50%;

    @include media-min-width($xs); }
  &.xs-2 {
    flex-basis: 16.66667%; }
  &.xs-4 {
    flex-basis: 33.33333%; }
  &.xs-6 {
    flex-basis: 50%; }
  &.xs-10 {
    flex-basis: 83.33334%; }

  &.xs-offset-6 {
    margin-left: 50%;

    @include media-min-width($sm); }
  &.sm-2 {
    flex-basis: 16.66667% !important; }
  &.sm-3 {
    flex-basis: 25%; }
  &.sm-4 {
    flex-basis: 33.33333%; }
  &.sm-5 {
    flex-basis: 41.66667%; }
  &.sm-6 {
    flex-basis: 50%; }
  &.sm-7 {
    flex-basis: 58.33334%; }
  &.sm-8 {
    flex-basis: 66.66666%;

    @include media-min-width($md); }
  &.md-2 {
    flex-basis: 16.66667%; }
  &.md-3 {
    flex-basis: 25%; }
  &.md-4 {
    flex-basis: 33.33333%; }
  &.md-6 {
    flex-basis: 50%; }
  &.md-8 {
    flex-basis: 66.66666%;

    @include media-min-width($lg); }
  &.lg-3 {
    flex-basis: 25%; }
  &.lg-4 {
    flex-basis: 33.33333%; }
  &.lg-5 {
    flex-basis: 41.66667%; }
  &.lg-7 {
    flex-basis: 58.33334%; }
  &.lg-8 {
    flex-basis: 66.66666%; } }

.hidden-sm-max {
  @include media-max-width($sm-max);
  display: none; }

.no-gutter {
  padding-left: 0;
  padding-right: 0; }

// General
body {
  padding-top: $header-height;
  background-color: #f8f8f8;

  @media (max-width: $lg) {
    padding-top: 130px; }

  @include media-max-width($xxs) {
      padding-top: $header-height - 30; } }

#root {
  overflow-x: hidden; }

// Pages
.section {
  padding-bottom: 25px;
  padding-top: 25px; }

// Texts
h1, h2, h3, h4, h5, h6, section, div, p, a, span, label {
  color: $dark-grey;
  font-family: $roboto; }

h2:not(.h1) {
  font-size: 30px;
  margin-bottom: 10px; }

h3 {
  font-size: 20px;
  margin-bottom: 10px; }

.title {
  font-size: 36px;
  font-family: $roboto;
  font-weight: 300;
  color: $dark-green;
  padding-bottom: 20px;

  &.bold {
    font-weight: 500; }

  &.no-space {
    padding-bottom: 0; }

  @media (max-width: $sm-max) {
    font-size: 24px; } }

h5.title {
  color: $black;
  font-size: 30px; }

.title > span { // react-intl's FormattedMessage
  font-family: $roboto;
  font-weight: 300;
  color: $green;
  padding-left: 0; }

p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;

  @media (max-width: $sm-max) {
    font-size: 12px;
    text-align: justify; } }

ul {
  list-style: disc;
  font-size: 16px;
  margin-bottom: 20px; }

// Buttons
a, button, .btn {
  background: transparent;
  cursor: pointer;
  @include transition(.33s);
  &:focus {
    outline: 0; } }

a {
  text-decoration: none; }

.btn {
  background: $green;
  border-radius: 2px;
  color: $white;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 1;
  max-width: 350px;
  text-align: center;
  width: 96%;

  @include media-max-width($xxs);
  font-size: 16px;

  &:hover {
    background: $green;
    color: #fff; } }

a.btn {
  line-height: 44px; }

.btn-outlined {
  background: $white;
  border: 3px solid $dark-green;
  color: $dark-green;
  display: flex;
  align-items: center;
  font-family: $quicksand;
  font-weight: 600;
  font-size: 25px;
  height: 68px;
  text-align: center;
  border-radius: 3px;
  padding: 0 30px;
  cursor: pointer;

  @media (max-width: $sm-max) {
    font-size: 14px;
    border-width: 2px;
    height: 40px; }

  &:hover {
    background: $dark-green;
    color: $white;
    box-shadow: 0px 3px 6px #00000029; }

  &.bigger-height {
    height: 84px; } }

.btn-outlined.dark-grey {
  border: 3px solid $dark-grey-2;
  color: $dark-grey-2;

  &:hover {
    background: $dark-grey-2;
    color: $white;
    box-shadow: 0px 3px 6px #00000029; }

  @media (max-width: $sm-max) {
    font-size: 14px;
    border-width: 2px;
    height: 40px; } }

.btn-contained {
  height: 84px;
  background: $dark-grey-3;
  box-shadow: 0px 5px 20px #D1D5DF80;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $quicksand;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  padding: 0 30px;

  &:hover {
    background: $dark-green;
    box-shadow: 0px 3px 6px #00000029; }

  &.active {
    background: $dark-green; }

  &.inverted-colors {
    background: $dark-green;
    &:hover {
      background: $dark-grey-3;
      box-shadow: 0px 3px 6px #00000029; }

    &.active {
      background: $dark-grey-3; } }

  &.medium-sized {
    font-size: 24px;
    height: 68px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      border-width: 2px;
      height: 40px; } }

  &.call-to-action {
    background: $yellow;
    color: $white;

    &:hover {
      background: $dark-yellow;
      color: $white;
      box-shadow: 0px 3px 6px #00000029; }

    @media (max-width: $sm-max) {
      font-size: 14px;
      border-width: 2px;
      height: 40px; }

    &.voltacare {
      background: $orange;

      &:hover {
        background: $dark-orange; } }

    &.voltacare-gradient {
      @include background-orange-gradient;

      &:hover {
        filter: brightness(0.95); } } } }

// Links
a.simple-link {
  color: $green;
  position: relative;
  &::after {
    background: $green;
    bottom: -1px;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    width: 0%;
    @include transition(.33s);
    &:hover::after {
      left: 0;
      width: 100%; } } }

// Forms
form {
  display: flex;
  flex-wrap: wrap; }

label {
  flex-basis: 100%;
  font-size: 18px;
  margin-bottom: 30px;

  @media (max-width: $sm-max) {
    line-height: 18px;
    font-size: 12px; } }

input,
select,
textarea {
  background: transparent;
  border: 1px solid $light-grey;
  border-radius: 3px;
  box-sizing: border-box;
  color: $dark-grey;
  display: block;
  font: 16px $roboto;
  height: 40px;
  margin-top: 5px;
  padding: 0 10px;
  width: 100%;
  @include transition(.33s);
  &:focus {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .3);
    outline: 0;
    &.error {
      border-color: #d00; } }

  @media (max-width: $sm-max) {
    font-size: 12px; } }

input[type="file"] {
      border: none; }

select {
  appearance: none;
  background: url(/img/arrow-down.svg) no-repeat 96% center;
  background-size: auto 50%;
  @include media-min-width($xs);
  background-position: 97% center;
  @include media-min-width($sm);
  background-position: 98% center; }

textarea {
  height: 100px;
  min-height: 40px;
  padding: 6px 10px;
  resize: vertical; }

.success {
  color: $green; }

.error {
  color: $sensor-red; }

// Pages (some huge pages have their own stylesheet file)

#blog {
  .title {
    margin-bottom: 0;

    .wrapper {
      padding: 0;
      > .col {
        padding: 0;
        @include media-min-width($md);
        &:last-child {
          padding-left: 15px; } } }

    .nav {
      margin-bottom: 50px;
      a {
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
          &:hover,
          &.active {
            color: $green; } } } } } }

#blog-post {
  .blog-search-wrapper,
  .blog-categories-wrapper,
  .blog-newsletter-wrapper,
  .blog-social-wrapper {
    padding: 0; } }

.blog-search-wrapper,
.blog-newsletter-wrapper {
  margin-bottom: 25px; }

.blog-newsletter-wrapper {
  .btn {
    margin-top: 10px;
    max-width: 100%;
    width: 100%; } }

#contact-us {
  .contact-us-map iframe {
    border: 0;
    height: 400px;
    pointer-events: none;
    width: 100%;

    .contact-us-contact {
      @include media-min-width(550px);
      max-width: 50%;

      &:first-child {
        @include media-min-width($sm);
        align-self: flex-start;
        max-width: 66.66666%;

        @include media-min-width($sm);
        align-self: flex-start;
        max-width: 33.33333%;

        @include media-max-width(549px);
        .title {
          margin-top: 30px;
          &:first-child .title {
            margin-top: 0; } }

        p {
          margin-bottom: 5px; }
        a {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          text-decoration: underline; } } } } }

#set-up {
  @include media-min-width($md);
  .col.md-6 {
    flex-basis: 45%;
    @include media-min-width($lg);
    .col.lg-5 {
      flex-basis: 35%; } } }

#acceptable-use,
#privacy-policy,
#terms-and-conditions,
#product-terms,
#consume-standard-terms,
#order-cancellation {
  .title {
    margin-top: 15px; }

  h5.title {
    font-size: 28px;
    color: $dark-green; }

  .section {
    padding: 10px 0; }

  .one-level-margin {
    margin-left: 40px; }

  .two-level-margin {
    margin-left: 80px; }

  span.bold, p.bold {
    font-weight: 700; }

  span.underlined, p.underlined {
    text-decoration: underline; }

  p {
    font-style: italic;
    line-height: 2;
    margin-bottom: 15px; }

  ul, table {
    font-size: 15px;
    font-style: italic;
    line-height: 1.4; }

  ul {
    list-style: disc;
    padding-left: 20px;
    li {
      padding: 4px 0; } }

  table {
    td, th {
      border: 1px solid $dark-grey;
      padding: 15px;
      th {
        font-family: $roboto; } } } }

#order-cancellation {
  ul, table, p {
    font-style: normal; }

  p.justified {
    margin: 0;
    text-align: justify; }

  form {
    flex-direction: column; }

  input {
    display: inline;
    width: 300px;
    border: none;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 0;
    padding: 0;
    margin: 0 4px;
    height: 1.2rem;
    text-align: center;

    &.small {
      width: 150px; }

    &.big {
      width: 450px; }

    &:focus {
      box-shadow: none; }

    &[type=submit] {
      font-size: 24px;
      line-height: 22px;
      padding: 15px 30px;
      border: 3px solid #4B8C2A;
      border-radius: 5px;
      height: auto;
      width: auto;

      @media (max-width: $sm-max) {
        font-size: 14px;
        padding: 13px 61px; }

      &:disabled,
      &[disabled] {
        border: 1px solid #cccccc;
        background-color: #cccccc;
        color: #fff; } } }

  textarea:focus {
    box-shadow: none; }

  .field-validation-error {
    border-color: #f44336; }

  .loading {
    opacity: 0.4; } }

#product-terms {
    ol {
      list-style: none;
      padding-left: 20px; } }

strong {
  color: #666;
  font-size: 16px;
  font-weight: bold;
  line-height: 150%;

  @include media-min-width($sm); }
.hidden-sm-plus {
  display: none !important; }
