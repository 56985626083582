@import 'utils/_variables';

.__team-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2, h3 {
    font-family: $quicksand;
    font-weight: bold; }

  h1, h2 {
    text-align: center; }

  h2, h3 {
    color: $dark-green; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    letter-spacing: 18px;
    line-height: 60px;
    text-shadow: 1px 0 0 #FFFFFF, -1px 0 0 #FFFFFF;

    @media (max-width: $sm-max) {
      font-size: 24px;
      letter-spacing: 0px;
      line-height: 30px;
      text-shadow: none;
      max-width: 60%; } }

  h2 {
    font-size: 45px;
    line-height: 80px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px; } }

  h3 {
    &.dark-grey {
      font-family: $roboto;
      font-size: 24px;
      line-height: 22px;
      color: $dark-grey-2;

      @media (max-width: $sm-max) {
        font-size: 18px;
        line-height: 24px; } } }

  ul {
    list-style: none; } }

.__team-header {
  background: transparent url('/img/team/banner.png') 0% 0% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $sm-max) {
    height: 160px; }

  h1 {
    @media (max-width: $sm-max) {
      width: 140px; } }

  p {
    color: #FFFFFF;
    font-family: $quicksand;
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-family: $roboto;
      font-size: 16px;
      line-height: 30px;
      margin-top: 16px;
      margin-bottom: 0; } } }


.__team-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $sm-max) {
    margin: 50px 0; }

  h2 {
    @media (max-width: $sm-max) {
      width: 230px; } }

  &#driving-change {
    p {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      width: 895.34px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        width: 303.31px; } } }

  &#meet-the-team #team-carousel,
  &#work-us #work-us-carousel {
    width: 1138px;

    @media (max-width: $sm-max) {
      width: 500px; }

    .__work-with-us-carousel-img {
      width: 335px;
      height: 335px;

      @media (max-width: $sm-max) {
        width: 151px;
        height: 151px; } } }

  &#technologies-you-love {
    h2 {
      @media (max-width: $sm-max) {
        width: 236px; } } }

  > .__team-technologies-you-love-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1143px;

    @media (max-width: $sm-max) {
      flex-direction: column;
      align-items: center;
      justify-content: center; }

    figure {
      width: 50%;

      @media (max-width: $sm-max) {
        width: unset;
        margin-bottom: 50px;

        img {
          width: 305px; } } }

    ul {
      width: 50%;

      @media (max-width: $sm-max) {
        width: 305px; }

      li {
        h3 {
          font-size: 30px;
          line-height: 80px;

          @media (max-width: $sm-max) {
            font-size: 18px;
            line-height: 53px;
            text-align: center; } }

        p {
          font-size: 20px;
          line-height: 34px;
          width: 497px;

          @media (max-width: $sm-max) {
            font-size: 12px;
            line-height: 18px;
            width: 302px; } } } } }

  > .__team-why-voltaware-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 952.5px;

    @media (max-width: $sm-max) {
      width: 90%; }

    .__team-why-voltaware-col {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;

      @media (max-width: $sm-max) {
        flex-direction: column; }

      figure {
        margin-right: 20px;

        @media (max-width: $sm-max) {
          margin: 0;

          img {
            width: 55.48px;
            height: 55.3px; } } }

      .__team-why-voltaware-article {
        h3 {
          font-size: 30px;
          line-height: 80px;

          @media (max-width: $sm-max) {
            font-size: 18px;
            line-height: 53px;
            text-align: center; } }

        p {
          font-size: 20px;
          line-height: 34px;
          width: 92%;

          @media (max-width: $sm-max) {
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            width: 138px; } } } } }

  &#open-positions {
    >p {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      width: 895.34px;

      @media (max-width: $sm-max) {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        width: 328px; } }

    a {
      color: $dark-green;
      font-weight: 600; }

    ul {
      display: flex;
      flex-direction: column;
      width: 1011px;
      margin-top: 100px;

      @media (max-width: $sm-max) {
        width: 251px;
        margin-top: 30px; }

      li {
        border: 2px $dark-grey solid;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $sm-max) {
          display: block;
          text-align: center; }

        section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 5px;

          @media (max-width: $sm-max) {
            align-items: center; }

          h3 {
            @media (max-width: $sm-max) {
              text-align: center; } }

          p {
            margin: 0;
            @media (max-width: $sm-max) {
              font-size: 12px;
              line-height: 22px; } } }

        a {
          font-family: $quicksand;
          font-weight: bold;
          font-size: 24px;
          color: #FFFFFF;
          background-color: #4B8C2A;
          border-radius: 5px;
          border: none;
          padding: 22px 38px;

          @media (max-width: $sm-max) {
            font-size: 12px;
            line-height: 16px;
            padding: 10px 30px; } } }

      li + li {
        margin-top: 30px; } } } }
