@import 'utils/_variables';

.our-technology-banner {
    background-color: #f1f1f1;

    .wrapper {
        padding: 0 40px 60px 40px;

        @media (max-width: 959px) {
            padding: 0 0 60px; } }

    h1 {
        letter-spacing: 2px;
        font-family: $quicksand;
        font-size: 60px;
        line-height: 100%;
        font-weight: 700;
        color: $green;
        text-align: center;
        margin-bottom: 20px;

        @media (max-width: 959px) {
            font-size: 40px; } }

    img {
        width: 100%;

        &.image-desktop {
            @media (max-width: 959px) {
                display: none; } }
        &.image-mobile {
            @media (min-width: 960px) {
                display: none; } } }

    .voltaware-platform {
        // margin-top: 32vw

        @media (max-width: 959px) {
            margin-top: 2rem;
            padding: 0 2rem; }

        h2 {
            font-family: $quicksand;
            font-weight: 700;
            font-size: 40px;
            color: #666;
            text-align: center;

            @media (max-width: 959px) {
                font-size: 35px; } }

        .platform-items {
            display: flex;
            max-width: $lg;
            margin: 50px auto 0;
            justify-content: space-between;
            position: relative;
            z-index: 10;

            @media (max-width: 959px) {
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 0; }

            .platform-item {
                flex: 0 0 calc(25% - 30px);
                display: flex;
                flex-direction: column;

                @media (max-width: 959px) {
                    flex: unset;
                    margin-top: 2rem; }

                .platform-body {
                    border-radius: 22px 22px 0 0;
                    background-color: rgba(0,0,0,.1);
                    padding: 30px 30px 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center; }

                .platform-icon {
                    height: 95px;

                    svg {
                        height: 95px; } }

                .platform-text {
                    background-color: $green;
                    box-shadow: 0 2px 4px 5px rgba(0,0,0,.07);
                    padding: 30px 16px;
                    border-radius: 8px;
                    color: #fff;
                    text-align: center;
                    font-size: 16px;
                    line-height: 150%;
                    margin-top: -10px;
                    height: 100%; } } } }

    .platform-subtext {
        background-color: #4B8C2A;
        padding: 100px 30px 50px;
        position: relative;
        z-index: 5;
        margin-top: -50px;

        p {
            max-width: 700px;
            color: #fff;
            line-height: 150%;
            margin: 0 auto;
            text-align: center;
            font-size: 18px;

            @media (max-width: 959px) {
                font-size: 16px; }

            a {
                color: #fff;
                text-decoration: underline; } } } }
