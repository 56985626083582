@import 'utils/_variables';

#faq {
    .section.container {
        display: block;
        padding: 0; }

    h2 {
        text-align: center;
        font-family: $quicksand;
        color: $green;
        font-size: 40px;
        font-weight: bold; }

    .faq-items {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px; }

    @media (max-width: 480px) {
        .faq-items {
            padding: 0; } } }
