@import 'utils/_variables';

// #home
//     background-color: #f1f1f1

.sections {
    h1, h2.h1 {
        color: $green;
        font-family: $quicksand;
        font-weight: 700;
        font-size: 55px;
        text-align: center;
        margin: 120px 0 80px;

        @media (max-width: 959px) {
            font-size: 40px;
            margin: 60px 0 24px;
            padding: 0 4rem; } }

    .sections-content {
        padding: 60px 0;

        @media (max-width: 959px) {
            padding: 3rem 0; }

        &:nth-of-type(even) {
            // background-color: #f8f8f8
            background-color: #f1f1f1;

            .wrapper {
                flex-direction: row-reverse;

                @media (max-width: 959px) {
                    flex-direction: column-reverse; } }

            .text {
                margin: 0 40px 0 0;

                @media (max-width: 959px) {
                    margin: 0 0 1rem !important; } } }

        // &:nth-of-type(odd)
        //     background-color: #f1f1f1

        &.absolute {
            padding-top: 120px;

            @media (max-width: 959px) {
                padding-top: 80px; }

            .wrapper {
                .image {
                    flex-basis: 55%; }

                .text {
                    @media (min-width: 960px) {
                        margin-left: 60px; } } } }

        &.background {
            padding: 120px 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center; }

        .wrapper {
            align-items: center;
            display: flex;
            max-width: $md;
            margin: 0 auto;
            padding: 0 30px;

            @media (max-width: 959px) {
                flex-direction: column; }

            .image {
                flex: 0 0 50%;

                img {
                    width: 100%; } }

            .text {
                margin-left: 40px;

                @media (max-width: 959px) {
                    margin: 1rem 0 0; }

                .text-title {
                    font-family: $quicksand;
                    font-size: 40px;
                    font-weight: 600;
                    color: $green;
                    max-width: 80%;
                    line-height: 120%;

                    @media (max-width: 959px) {
                        font-size: 28px;
                        max-width: unset; } }

                p, ul {
                    font-size: 20px;
                    line-height: 150%;
                    margin-top: 20px; }

                ul {
                    list-style-type: circle;
                    padding-left: 40px; } } } } }
