@import 'utils/_variables';

#about-us {
  padding: 0 30px;

  .about-us-team {
    display: block;
    padding: 80px 0;

    @media (max-width: $sm-max) {
      padding: 50px 0; } }

  .about-us-header {
    h1 {
        color: $dark-green;
        padding: 20px 0; }
    @media (min-width: 960px) {
      padding: 0 80px;

      > p {
        margin-top: 24px;

        @media (max-width: $sm-max) {
          font-size: 18px; } } } }

  .about-us-members {
    display: flex;
    flex-wrap: wrap;
    margin: 60px -15px 0;

    .about-member {
      flex: 0 0 calc(25% - 30px);
      margin: 0 15px 30px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 6px 24px rgba(0,0,0,.2);
      border-radius: 8px;


      @media (max-width: 959px) {
        flex-basis: calc(50% - 30px);

        @media (max-width: 699px) {
          flex-basis: calc(100% - 30px); } }

      $photo-height: 280px;
      .about-member-img {
        height: $photo-height; }

      img {
        border-radius: 8px 8px 0 0;
        filter: grayscale(1);
        height: $photo-height;
        object-fit: cover;
        width: 100%; }

      .about-member-info {
        background-color: #fff;
        padding: 20px;
        margin-top: -20px;
        position: relative;
        z-index: 5;
        height: 100%;
        border-radius: 0 0 8px 8px; }

      .member-role {
        padding-bottom: 10px;
        position: relative;

        &:after {
          content: '';
          width: 48px;
          height: 1px;
          background-color: #979797;
          position: absolute;
          left: 0;
          bottom: 0; }

        span {
          font-family: $quicksand;
          font-size: 14px;
          text-transform: uppercase;
          color: #333; } }

      .member-name {
        font-size: 24px;
        font-family: $quicksand;
        color: $green;
        font-weight: bold;
        margin: 8px 0 16px;

        @media (max-width: $sm-max) {
          font-size: 18px; } }

      .member-about {
        font-size: 15px;
        line-height: 150%;

        .read-more-button {
          color: $green;
          cursor: pointer; } } } } }
