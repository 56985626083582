@import 'utils/_variables';

.__request-a-demo-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1, h2 {
    width: 1100px; }

  h1, h2, h3 {
    font-family: $quicksand;
    font-weight: bold; }

  h2, h3 {
    color: $dark-green; }

  h2, h3 {
    text-align: center; }

  h1 {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px; } }

  h2 {
    font-size: 45px;
    line-height: 80px;
    margin-bottom: 30px;

    @media (max-width: $sm-max) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px; } }

  h3 {
    &.dark-grey {
      font-size: 26px;
      line-height: 40px;
      color: $dark-grey-2;

      @media (max-width: $sm-max) {
        font-size: 16px;
        line-height: 24px; } } }

  p {
    font-family: $roboto;
    font-size: 24px;
    line-height: 34px;
    color: $dark-grey;

    @media (max-width: $sm-max) {
      font-size: 12px;
      line-height: 18px; } }

  a.btn-outlined {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 37px 42.87px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      line-height: 24px;
      padding: 12px 40.5px; } }

  label {
    font-family: $roboto;
    color: #95989A;
    font-size: 24px;
    letter-spacing: 0.72px;
    line-height: 32px;
    margin-left: 10px;
    margin-top: 45px;

    @media (max-width: $sm-max) {
      font-size: 14px;
      letter-spacing: 0.42px;
      line-height: 19px;
      margin-left: 10px; } }

  input[type="text"], input[type="email"], textarea {
    font-size: 24px;
    letter-spacing: 0.72px;
    line-height: 32px;
    border: 3px solid #B4B4B4;
    border-radius: 5px;
    width: 497px;
    height: 60px;

    @media (max-width: $sm-max) {
      border: 2px solid #B4B4B4;
      width: 285.75px;
      margin-top: 10px; } }

  textarea {
    height: 140px;
    resize: none; }

  input[type="submit"] {
    font-size: 24px;
    line-height: 22px;
    padding: 26px 54px;
    border-radius: 5px;
    height: auto;
    width: auto;

    @media (max-width: $sm-max) {
      font-size: 14px;
      padding: 13px 61px; } } }

.__request-a-demo-header {
  background: transparent url('/img/demo/banner.png') 20% 50% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-max) {
    align-items: center;
    height: 160px; }

  h1 {
    text-transform: capitalize;
    font-size: 45px;
    line-height: 60px;
    letter-spacing: 14px;

    @media (max-width: $sm-max) {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0px;
      width: 214px;
      text-align: center;
      margin: auto; } } }

.__request-a-demo-section {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;

  @media (max-width: $sm-max) {
    padding: 50px 0; }

  h2 {
    @media (max-width: $sm-max) {
      width: 198px; } }

  &#discover-iris {
    p {
      width: 966px;

      @media (max-width: $sm-max) {
        font-size: 14px;
        width: 303.31px; } }

    .__loading {
      opacity: 0.4; }

    .__form-wrap {
      display: flex;
      width: 856.5px;
      margin-top: 60px;

      figure {
        margin-top: 25px; }

      @media (max-width: $sm-max) {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        figure {
          margin-top: 25px; } }

      .__demo-form {
        display: flex;
        flex-direction: column;
        width: 541px;
        margin-left: 50.5px;

        @media (max-width: $sm-max) {
          width: 285.75px;
          margin-left: 0px; }

        .__form-row {

          @media (max-width: $sm-max) {
            margin-top: 60.31px; }

          .__form-validation-error {
            border: 1px solid #f44336; } }

        .__form-row + .__form-row {
          margin-top: 25px; }

        p {
          color: #95989A;
          font-style: italic;
          font-size: 18px;
          line-height: 20px;
          margin-top: 15px;
          width: 541px;

          @media (max-width: $sm-max) {
            font-size: 14px;
            margin-top: 24px;
            width: 292px; }

          a {
            color: $green;
            text-decoration: underline; } }

        .__form-actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-top: 45px;

          @media (max-width: $sm-max) {
            align-items: center;
            justify-content: center;
            margin-top: 60px; }

          input[type="submit"] {
            margin-right: 44.25px;

            @media (max-width: $sm-max) {
              margin-right: 0; } } } } } }

  &#contact-us {
    background-color: #FBFBFB;
    padding-bottom: 50px;

    .__contact-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1049px;

      @media (max-width: $sm-max) {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 259px; }

      .__card {
        width: 497px;

        @media (max-width: $sm-max) {
          width: 259px; }

        h3 {
          font-size: 30px;
          line-height: 80px;
          text-align: left;

          @media (max-width: $sm-max) {
            font-size: 16px;
            line-height: 20px; } }

        h3 + h3 {
          @media (max-width: $sm-max) {
            margin-top: 30px; } }

        p {
          font-size: 20px;
          line-height: 34px;

          @media (max-width: $sm-max) {
            font-size: 14px;
            line-height: 24px; } } } } }

  &#follow-us {
    background-color: #FBFBFB;
    padding-top: 0px;

    @media (max-width: $sm-max) {
      margin-top: -9.31px; }

    .__nav-bar {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 492px;

      @media (max-width: $sm-max) {
        width: 213.25px; } }

    .__nav-ico {
      figure {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @media (max-width: $sm-max) {
            display: block;
            max-width: 30.9px;
            max-height: 30.9px;
            width: auto;
            height: auto; } } }


      p {
        font-size: 14px;
        line-height: 34px;

        @media (max-width: $sm-max) {
          font-size: 8px;
          line-height: 19px; } } } } }
