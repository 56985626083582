@import 'utils/_mixins';
@import 'utils/_variables';

#related-wrapper {
    h3 {
        font-family: $quicksand;
        font-size: 32px;
        color: $green;
        font-weight: bold;
        margin-top: 10px;

        @media (max-width: $sm-max) {
            font-size: 18px; } } }

#blog-related-posts {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media (max-width: $sm-max) {
        margin: 0; }

    .blog-post {
        flex: 0 0 calc(25% - 30px);
        padding: 0;
        margin: 0 15px 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media (max-width: 1199px) {
            flex-basis: calc(50% - 30px); }

        @media (max-width: 759px) {
            flex-basis: calc(100% - 30px); }

        > a {
            z-index: 1; }

        img {
            border-radius: 22px 22px 0 0;
            overflow: hidden;
            position: relative; }

        .post-title {
            padding: 24px;
            color: $green;
            font-size: 24px;
            font-weight: bold;
            font-family: $quicksand;
            box-shadow: 0 4px 20px rgba(0,0,0,.1);
            border-radius: 22px;
            margin-top: -22px;
            position: relative;
            z-index: 3;
            background-color: #fff;

            span {
                font-size: 12px;
                color: #666;
                display: block;
                border-bottom: 1px solid #888;
                padding-bottom: 8px;
                margin-bottom: 12px;
                font-weight: normal;
                text-transform: uppercase; }

            a {
                color: $green;
                font-size: 24px;
                font-weight: bold;
                font-family: $quicksand;

                @media (max-width: $sm-max) {
                    font-size: 14px; } } }

        img {
            width: 100%; } } }
