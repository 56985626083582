@import 'utils/_variables';

#blog {
    background-color: #fff;
    padding: 40px 0;

    @media (max-width: 959px) {
        padding: 40px 30px; }

    .flex-wrap {
        flex-wrap: wrap;
        margin: 0 -15px; }

    .blog-post {
        flex: 0 0 calc(33.33% - 30px);
        margin: 15px;

        @media (max-width: 959px) {
            flex-basis: calc(50% - 30px); }

        @media (max-width: 699px) {
            flex-basis: calc(100% - 30px); }

        .blog-post-link {
            display: flex;
            flex-direction: column;
            height: 100%; }

        .blog-post-img {
            border-radius: 22px 22px 0 0;
            overflow: hidden;
            height: 15vw;
            min-height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid rgba(0,0,0,.1); }

        .blog-post-info {
            flex-grow: 1;
            background-color: #fff;
            border-radius: 22px;
            padding: 25px 20px;
            border: 1px solid rgba(0,0,0,.1);
            margin-top: -25px;

            .blog-post-author {
                color: #333;
                font-size: 12px;
                text-transform: uppercase;
                padding-bottom: 14px;
                position: relative;

                &:before {
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 50px;
                    height: 1px;
                    position: absolute;
                    background-color: #999; } }

            .blog-post-title {
                font-size: 24px;
                color: $green;
                font-family: $quicksand;
                font-weight: bold;
                margin-top: 20px;

                @media (max-width: $sm-max) {
                    font-size: 18px; } }

            p {
                margin-top: 10px; }

            .blog-post-btn {
                appearance: none;
                background-color: none;
                color: $sensor-red;
                border: none;
                outline: none;
                padding: 0;
                margin: 0;
                font-size: 12px; } } } }
