@import 'utils/_mixins';
@import 'utils/_variables';


.discover-item {
    padding: 0 0 50px;
    @include media-min-width($sm) {
        padding-bottom: 75px; }

    img {
        display: block;
        max-width: 180px;
        @include media-max-width($xs-max) {
            margin: 0 auto 30px; } }
    p {
        line-height: 1.5;
        margin-bottom: 10px; } }

.discover-video-btn {
    @include media-max-width($xs-max) {
        text-align: center; } }
