@import 'utils/_variables';

.aside {
    background: url(/img/technology/power-pocket.jpg) no-repeat center top;
    padding: 120px 0 100px;
    background-size: 100% auto;
    position: relative;
    min-height: 700px;

    @media (max-width: 959px) {
        padding: 5.5rem 0;
        background: linear-gradient(180deg, #D5E4AD 0%, #4B8C2A 100%) !important;
        min-height: unset; }

    &.power {
        @media (min-width: 960px) {
            min-height: 1200px; } }

    h1 {
        font-family: $quicksand;
        font-weight: 300;
        font-size: 60px;
        color: #111;
        text-align: center;

        @media (max-width: 959px) {
            font-size: 40px;
            padding: 0 3rem; } }

    .app {
        margin-top: 150px;

        @media (max-width: 959px) {
            margin-top: 40px;
            padding: 0 2rem; }

        .container {
            flex-direction: column; }

        p {
            max-width: 460px;
            font-size: 17px;
            line-height: 150%;

            @media (max-width: 959px) {
                width: 100%;
                max-width: unset; } }

        .stores {
            display: flex;
            margin-top: 50px;

            @media (max-width: 959px) {
                margin-top: 10px; }

            .stores-a {
                width: 167px;

                @media (max-width: 959px) {
                    width: 45%;
                    max-width: 167px; }

                &:not(:first-child) {
                    margin-left: 28px;

                    @media (max-width: 959px) {
                        margin-left: 8px; } }

                img {
                    width: 100%; } } } } }
