@import 'utils/_mixins';
@import 'utils/_variables';


#download-component {
    .download-description {
        line-height: 2;
        margin-bottom: 30px; }

    .download-links a {
        float: left;
        max-width: 200px;
        width: 45%;

        @include media-max-width($xxs) {
            float: none; }

        &:last-child {
            margin-left: 10px;
            max-width: 225px;
            width: 51%;
            @include media-min-width($sm) {
                margin-left: 30px; } }
        img {
            display: block;
            max-width: 100%; } } }
