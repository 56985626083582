@import 'utils/_mixins';
@import 'utils/_variables';

.faq-item-wrapper {
    flex: 0 0 100%;
    margin: 0 0 15px 0;

    &:last-child {
        margin-bottom: 0; }

    .faq-title {
        font-size: 26px;
        padding: 0 20px;
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $quicksand;
        font-weight: bold;
        color: $green;

        @media (max-width: $sm-max) {
            font-size: 18px; } }

    ul li.active p {
        max-height: min-content; } }

.faq-item {
    .title {
        display: inline-block;
        cursor: pointer; }

    .active .up-arrow {
        transform: rotate(180deg); }

    .up-arrow {
        height: 20px;
        margin-left: 15px;
        transform: rotate(90deg);
        transition: transform 0.6s; }

    .faq-content {
        height: fit-content;
        overflow: hidden;
        transition: height 0.6s;
        padding: 0 20px;

        * {
            font-family: $roboto; }

        p a {
            text-decoration: underline; }

        .answer {
            ul {
                list-style-type: disc;
                margin-left: 20px; }
            li p {
                padding-left: 0; } } }

    ul {
        padding-left: 20px;
        list-style-type: none;

        li {
            padding-left: 13px;
            position: relative;

            img {
                display: inline-block;
                margin-top: 4px;
                height: 14px; }

            &.active {
                img {
                    transform: rotate(90deg); } }

            .question {
                display: flex; }

            > p {
                margin: 10px 0;
                max-height: 0;
                overflow: hidden;
                line-height: 150%;
                @include transition(.44s);
                padding-left: 40px; } }

        button {
            font-weight: bold;
            color: $dark-grey; }

        button, a, p {
            appearance: none;
            font-size: 18px;
            line-height: 120%;
            border: 0;
            outline: 0;
            text-align: left; }

        @media (min-width: 960px) {
            a:hover, button:hover {
                color: $green; } } } }

@media (max-width: 480px) {
    .faq-item-wrapper {
        .faq-title {
            padding: 0 10px; } }

    .faq-item {
        .faq-content {
            padding: 0; }

        .faq-content ul {
            padding: 0 2px; } } }
