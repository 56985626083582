@import 'utils/_variables';

.partners-investors {
    .partners-investors-content {
        width: 100%;
        display: flex;
        justify-content: center;

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 15px;

            @media (max-width: $sm-max) {
                max-width: 90%; }

            .box {
                flex: 2;
                display: flex;
                flex-wrap: wrap;
                border: 2px solid #CCCCCC;
                border-radius: 8px;
                gap: 15px 0;
                min-width: 300px;
                padding: 40px 15px;

                &:first-child {
                    flex: 4; }

                @media (max-width: $sm-max) {
                    flex: 1; }

                a {
                    flex-grow: 1;
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: $sm-max) {
                        width: 44%;
                        margin: 4px; }

                    img {
                        max-width: 75%;

                        @media (max-width: $sm-max) {
                            max-width: 100px; } }

                    &.reduced-width-logo {
                        img {
                            max-width: 60%; } } } } } } }
