@import 'utils/_variables';

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2D2F33;
  width: 100%;

  a, p {
    font-family: $roboto;
    color: #FFFFFF;
    line-height: 32px; }

  p {
    font-size: 21px;

    @media (max-width: $sm-max) {
      font-size: 18px; } }

  a {
    font-size: 26px;

    @media (max-width: $sm-max) {
      font-size: 21px;
      line-height: 28px; }

    &:hover {
      text-decoration: underline; } }

  .__nav-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 83px;
    margin-left: 293px;
    margin-right: 292.49px;
    max-width: 1920px;

    @media (max-width: $sm-max) {
      flex-direction: column;
      align-items: center; }

    .__nav-column {
      display: flex;
      justify-content: center;
      width: 25%;

      @media (max-width: $sm-max) {
        width: 100%; }

      & + .__nav-column {
        @media (max-width: $sm-max) {
          margin-top: 70px; } }

      ul {
        list-style: none;

        li {
          @media (max-width: $sm-max) {
            text-align: center; } }

        li + li {
          margin-top: 15px; } }

      .__inline {
        li {
          display: inline-flex;

          & + li {
            margin-left: 20.64px; } } } } }

  p {
    margin-top: 83px;
    margin-bottom: 58px; }

  .last-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 0;
      font-family: 'Quicksand', sans-serif;
      color: #AAAAAA;
      font-size: 24px; }

    & > *:not(:last-child) {
      margin-bottom: 30px; }

    .email {
      display: flex;

      img {
        margin-right: 10px; } }

    .amazon-logo {
      width: 200px; }

    @media (max-width: $sm-max) {
      padding: 0 20px;

      & > * {
        font-size: 20px;
        text-align: justify; } } } }
