@import 'utils/_variables';

.voltaware-rollout {
    background-color: rgba(225,233,203,.6);
    padding: 90px 30px;

    @media (max-width: $sm-max) {
        padding: 45px 30px; }

    .wrapper {
        max-width: $lg;
        margin: 0 auto; }

    h2 {
        font-family: $quicksand;
        font-size: 40px;
        color: $green;
        text-align: center;
        font-weight: bold;

        @media (max-width: 959px) {
            font-size: 30px; } }

    .diagram {
        align-items: center;
        display: flex;
        margin-top: 80px;

        @media (max-width: 959px) {
            margin-top: 40px;
            flex-direction: column; }

        .utility {
            @media (min-width: 960px) {
                flex: 1; }

            .arrow {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;

                @media (max-width: 959px) {
                    display: none; }

                &.upside {
                    margin: 10px 0 0 0;

                    svg {
                        transform: scaleY(-1); } } }

            .utility-box {
                .utility-title {
                    background-color: #7436B3;
                    color: #fff;
                    font-family: $quicksand;
                    font-size: 32px;
                    padding: 60px 60px 90px;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 22px; }

                .utility-text {
                    background-color: #EDF2E0;
                    margin-top: -30px;
                    font-size: 16px;
                    padding: 30px;
                    border-radius: 22px;
                    box-shadow: 0 4px 24px rgba(0,0,0,.3);
                    line-height: 150%;
                    text-align: center; } } }

        .direct-upselling {
            max-width: 100%;
            margin-top: 40px;

            @media (min-width: 960px) {
                flex: 3;
                margin: 0 0 0 30px; }

            .direct-upselling-box {
                &:nth-child(2) {
                    margin-top: 50px;

                    .direct-upselling-title {
                        background-color: rgba(102,102,102,.5);
                        border-color: #666;
                        color: #666; }

                    .direct-upselling-body .direct-upselling-body-box {
                        color: #666;

                        &:first-child {
                            flex: 3; } } }

                .direct-upselling-title {
                    background-color: rgba(106,180,45,.3);
                    border: 1px solid $green;
                    border-radius: 22px;
                    padding: 30px 30px 70px;
                    text-align: center;
                    font-family: $quicksand;
                    font-weight: bold;
                    font-size: 32px;
                    color: $green; }

                .direct-upselling-body {
                    display: flex;
                    margin: -40px -15px 0;

                    @media (max-width: 959px) {
                        flex-direction: column; }

                    .direct-upselling-body-box {
                        flex: 1;
                        background-color: #fff;
                        padding: 40px;
                        border-radius: 8px;
                        font-size: 16px;
                        color: $green;
                        margin: 0 15px;
                        text-align: center;
                        box-shadow: 0 4px 24px rgba(0,0,0,.2);
                        line-height: 150%;

                        @media (max-width: 959px) {
                            &:not(:first-child) {
                                margin-top: 16px; } } } } } } } }
